import React from "react";
import img from '../../Assets/Images/new.jpg'
import img4 from '../../Assets/Images/abc.jpg'
import img1 from '../../Assets/Images/businessmen-hands-white-table-with-documents-drafts.jpg'
import img2 from '../../Assets/Images/stock-exchange-information-board-graphic.jpg'
import img3 from '../../Assets/Images/stock-exchange-information-board-graphic.jpg'
import p1 from '../../Assets/Images/portrait-young-indian-top-manager-t-shirt-tie-crossed-arms-smiling-white-isolated-wall.jpg'
import p2 from '../../Assets/Images/smiling-business-woman-with-folded-hands-against-white-wall-toothy-smile-crossed-arms.jpg'
import p3 from '../../Assets/Images/cheerful-curly-business-girl-wearing-glasses.jpg'
import p4 from '../../Assets/Images/young-bearded-man-with-striped-shirt.jpg'
import { Fade } from "react-bootstrap";
import {Row,Col,Container} from "react-bootstrap";
import img5 from '../../Assets/Images/young-bearded-man-with-striped-shirt.jpg';
import Carousel from 'react-bootstrap/Carousel';
import Partners from "../About/partner";
import senior from '../../Assets/Images/senior.jpeg'
import { Card, CardGroup } from 'react-bootstrap';
import { FaInstagram ,FaTwitter,FaFacebook} from "react-icons/fa";
import sahad from '../../Assets/Images/sahad.jpeg'
import bayram from '../../Assets/Images/bayram.jpeg'
import { FaDollarSign } from "react-icons/fa";

import p5 from '../../Assets/Images/indoor-shot-displeased-intense-european-man-with-beard-moustache-trendy-glasses-holding-hands-crossed-chest-looking-with-await-being-offended-wanting-hear-apology.jpg'
function Mainabout(){
    return(
        <div>
<section className="page-title centred" style={{backgroundImage: `url(${img4 })`}}>
        <div className="container">
            <div className="content-box"><h2>About us</h2></div>
        </div>
    </section>
   
    <section className="who-we-are centred">
        <div className="container">
            <h2 className="sec-title">Who We Are</h2>
            <div className="title-text" >
            At MBN Investment Consultancy, we understand that making sound 
investment decisions requires a deep understanding of the markets, <br></br>
diligent analysis, and a tailored approach. With our team of experienced 
consultants and financial experts,<br></br> we offer a wide range of services 
designed to meet your unique investment needs.
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="single-item">
                   
                        <figure className="img-box"><a href="#"><img src={img1} alt=""/></a></figure>
                        <div className="lower-content">
                            
                            <h4><a href="#">LARGEST COMMUNITY</a></h4>
                            <div className="text" style={{textAlign:'justify'}}>Join our thriving financial consultancy community, where expertise meets innovation. With a global network of seasoned professionals, we offer unparalleled insights into investment strategies, wealth management, and financial planning. </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="single-item">
                        <figure className="img-box"><a href="#"><img src={img} alt=""/></a></figure>
                        <div className="lower-content">
                            <h4><a href="#">24 HOURS SERVICE</a></h4>
                            <div className="text" style={{textAlign:'justify'}}>Experience round-the-clock financial guidance with our 24-hour consultancy service. Our dedicated experts are available day and night to address your investment, budgeting, and financial planning needs.</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="single-item">
                        <figure className="img-box"><a href="#"><img src={img3} alt=""/></a></figure>
                        <div className="lower-content">
                            <h4><a href="#">LIFETIME SUPPORT</a></h4>
                            <div className="text" style={{textAlign:'justify'}}>Embark on a journey to financial excellence with our consultancy's lifetime support. From your initial goals to long-term milestones, our experts stand by you, offering unwavering guidance.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="services-section">
        <div className="auto-container">
            <div className="sec-title">
                <span className="title">Opening Doors to 
</span>
                <h2> Financial Success</h2>
            </div>

            <div className="row">
               
                <div  className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div className="inner-box" style={{backgroundImage: `url(${img1 })`}}>
                        <div className="icon-box"><span><img src={img}/></span></div>
                        <h4><a href="property-detail.html">OUR MISSION</a></h4>
                        <div className="text" style={{textAlign:'justify'}}>Our mission is to empower 
our clients to achieve their 
financial goals by providing 
exceptional investment 
consultancy services. We 
strive to be their trusted 
partner, leveraging our 
expertise, integrity, and 
market insights to guide 
them towards long-term 
financial success<br></br><br></br><br></br></div>
                       
                    </div>
                </div>

               
                <div  className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div className="inner-box" style={{backgroundImage: `url(${img1 })`}}>
                        <div className="icon-box"><span><img src={img}/></span></div>
                        <h4><a href="property-detail.html">OUR VISION</a></h4>
                        <div className="text" style={{textAlign:'justify'}}>Our vision is to be a leading 
investment consultancy firm in the 
UK, recognized for our unwavering 
commitment to delivering superior 
value to our clients. We aim to 
foster long-lasting relationships 
built on trust, transparency, and 
outstanding performance, while 
making a positive impact on the 
financial well-being of our clients 
and the communities we serve.</div>
                        
                    </div>
                </div>

                
                <div  className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div className="inner-box" style={{backgroundImage: `url(${img1 })`}}>
                        <div className="icon-box"><span><img src={img}/></span></div>
                        <h4><a href="property-detail.html">OUR GOALS</a></h4>
                        <div className="text" style={{textAlign:'justify'}}>Client Success: 
Our primary goal is to help our clients 
achieve their financial aspirations. 
We are dedicated to understanding 
their unique needs, developing 
tailored investment strategies, and 
providing proactive advice to help 
them navigate the ever-changing 
investment landscape.<br></br><br></br></div>
                       
                    </div>
                </div>
 </div>
        </div>
    </section>
    </section>
    {/* <section className="testimonial-section sec-pad centred" style={{backgroundImage: `url(${img2 })`}}>
        <div className="container">
            <h2 className="sec-title">clients testimoinal</h2>
            <div className="title-text"></div>
            <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 offset-lg-2">
                    <div className="testimonial-slider">
                        <div className="testimonial-content">
                            <div className="author-info">
                           
                            <Carousel>
     
      <Carousel.Item >  <h4 >Abraham</h4>
      <figure class="author-thumb"><img src={img5} alt=""/></figure>
      <p><div className="text">

      I can confidently say that MBN Consultancy exceeded my expectations in every way. They combined expertise, transparency, and a client-centric approach to deliver a financial consultancy service that truly made a positive impact on my financial journey. 
                            </div></p>
      </Carousel.Item>
     
      
      <Carousel.Item >  <h4>Alex</h4>
      <figure class="author-thumb"><img src={p5} alt=""/></figure>
      <p><div className="text">
      I wholeheartedly recommend MBN to anyone seeking reliable and effective financial advice.
                            </div></p>
      </Carousel.Item>
   
      <Carousel.Item >  <h4>Robert</h4>
      <figure class="author-thumb"><img src={p1} alt=""/></figure>
      <p><div className="text">
      The recommendations and strategies presented by MBN were well-researched and tailored to my specific circumstances. The team showed a genuine dedication to optimizing my financial situation, and their expertise was evident in the results we achieved.
                            </div></p>
      </Carousel.Item>
      
    </Carousel>
                            </div>
                            
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    <section className="features-section">
        <div className="container">
            <h2 className="sec-title centred">MBN FEATURES</h2>
            <div className="title-text centred">MBN ensures the following features:</div>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="single-item">
                        <div className="icon-box"><i className="flaticon-magic"></i></div>
                        <h4><a href="#"> Independence</a></h4>
                        <div className="text">Independence in financial consultancy ensures unbiased advice by prioritizing client interests over personal gains, while transparently managing any potential conflicts of interest.</div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="single-item">
                        <div className="icon-box"><i className="flaticon-roller"></i></div>
                        <h4><a href="#">Impartiality</a></h4>
                        <div className="text">Impartiality in financial consultancy entails unbiased recommendations devoid of favoritism towards specific products or services, emphasizing client-centric solutions aligned with individual financial goals.</div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="single-item">
                        <div className="icon-box"><i className="flaticon-measure"></i></div>
                        <h4><a href="#">A specialist skillset</a></h4>
                        <div className="text">A specialist skillset in financial consultancy encompasses a deep expertise in intricate financial strategies, enabling tailored solutions to address diverse client needs with precision and insight.</div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="single-item">
                        <div className="icon-box"><i className="flaticon-shield"></i></div>
                        <h4><a href="#">Flexibility</a></h4>
                        <div className="text">Flexibility in financial consultancy denotes the adeptness to adapt strategies and advice to diverse economic landscapes, ensuring optimal client outcomes in ever-changing market conditions.</div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="single-item">
                        <div className="icon-box"><i className="flaticon-leaf"></i></div>
                        <h4><a href="#">Cost effectiveness</a></h4>
                        <div className="text">Cost effectiveness in financial consultancy signifies delivering high-value advisory services while optimizing resource allocation, resulting in efficient and affordable solutions for clients' financial objectives.</div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="single-item">
                        <div className="icon-box"><i className="flaticon-graphic-design"></i></div>
                        <h4><a href="#"> Confidentiality</a></h4>
                        <div className="text">Confidentiality in financial consultancy upholds strict privacy measures to safeguard client information, fostering a trusted environment for open discussions and secure management of sensitive financial matters.</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <section className="fact-counter centred" style={{backgroundImage: `url(${img })`}}>
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12 counter-column">
                    <div className="single-counter-content">
                        <div className="content-box">
                            <article className="column wow fadeIn" data-wow-duration="0ms">
                                <div className="count-outer"><span className="count-text" data-speed="1500" data-stop="21">5</span></div>
                            </article>
                            <div className="text">Years Experience</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 counter-column">
                    <div className="single-counter-content">
                        <div className="content-box">
                            <article className="column wow fadeIn" data-wow-duration="0ms">
                                <div className="count-outer"><span className="count-text" data-speed="1500" data-stop="475">100+</span></div>
                            </article>
                            <div className="text">Happy Clients</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 counter-column">
                    <div className="single-counter-content">
                        <div className="content-box">
                            <article className="column wow fadeIn" data-wow-duration="0ms">
                                <div className="count-outer"><span className="count-text" data-speed="1500" data-stop="150">20</span></div>
                            </article>
                            <div className="text">Projects Completed</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 counter-column">
                    <div className="single-counter-content">
                        <div className="content-box">
                            <article className="column wow fadeIn" data-wow-duration="0ms">
                                <div className="count-outer"><span className="count-text" data-speed="1500" data-stop="30">200+</span></div>
                            </article>
                            <div className="text">Best Employees</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    {/* <section className="our-agent centred sec-pad">
        <div className="container">
            <h2 className="sec-title">Our Team</h2>
            <div className="title-text">The best team in financial consultancy embodies diverse expertise, seamless collaboration, and a shared commitment to delivering innovative solutions that empower clients to navigate complex financial landscapes with confidence.</div>
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12 agent-column">
                    <div className="single-item">
                        <ul className="agent-social">
                        <li><a ><i className="fa fa-facebook"><FaFacebook></FaFacebook></i></a></li>
                <li><a ><i className="fa fa-dribbble"><FaInstagram></FaInstagram></i></a></li>
                <li><a ><i className="fa fa-twitter"><FaTwitter></FaTwitter></i></a></li>
                        </ul>
                        <figure className="img-box"><a ><img src={p1} alt=""/></a></figure>
                        <div className="lower-content">
                            <h4><a >Alvis Milan</a></h4>
                            <div className="text">Advisor</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 agent-column">
                    <div className="single-item">
                        <ul className="agent-social">
                        <li><a ><i className="fa fa-facebook"><FaFacebook></FaFacebook></i></a></li>
                <li><a ><i className="fa fa-dribbble"><FaInstagram></FaInstagram></i></a></li>
                <li><a ><i className="fa fa-twitter"><FaTwitter></FaTwitter></i></a></li>
                        </ul>
                        <figure className="img-box"><a><img src={p4} alt=""/></a></figure>
                        <div className="lower-content">
                            <h4><a >Jhon Doe</a></h4>
                            <div className="text">Advisor</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 agent-column">
                    <div className="single-item">
                        <ul className="agent-social">
                        <li><a ><i className="fa fa-facebook"><FaFacebook></FaFacebook></i></a></li>
                <li><a ><i className="fa fa-dribbble"><FaInstagram></FaInstagram></i></a></li>
                <li><a ><i className="fa fa-twitter"><FaTwitter></FaTwitter></i></a></li>
                        </ul>
                        <figure className="img-box"><a ><img src={p3} alt=""/></a></figure>
                        <div className="lower-content">
                            <h4><a >Hugo Smith</a></h4>
                            <div className="text">Advisor</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 agent-column">
                    <div className="single-item">
                        <ul className="agent-social">
                        <li><a ><i className="fa fa-facebook"><FaFacebook></FaFacebook></i></a></li>
                <li><a ><i className="fa fa-dribbble"><FaInstagram></FaInstagram></i></a></li>
                <li><a ><i className="fa fa-twitter"><FaTwitter></FaTwitter></i></a></li>
                        </ul>
                        <figure className="img-box"><a ><img src={p5}alt=""/></a></figure>
                        <div className="lower-content">
                            <h4><a >Jenny Salvia</a></h4>
                            <div className="text">Advisor</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    <section className="our-agent centred sec-pad">
        <div className="container">
            <h2 className="sec-title">Our Team</h2>
            <div className="title-text">The best team in financial consultancy embodies diverse expertise, seamless collaboration, and a shared commitment to delivering innovative solutions that empower clients to navigate complex financial landscapes with confidence.</div>
           </div>
           </section>
           <Container>
     <Card  style={{marginTop:-60}}>
          <Card.Img  style={{ borderRadius: 150, width: 180, height: 180 }} />
          {/* <img src={anas}  /> */}
          <Card.Body>
            <Card.Title style={{color:'#c59b6b'}}>Dr. Mubarak Alkaabi</Card.Title>
            <Card.Subtitle className="mb-2 text-muted"> Founder&CEO</Card.Subtitle>
            <Card.Text>
              
            </Card.Text>

          </Card.Body>
        </Card>
        <br></br>

        <Card>       
          <Card.Body>
            <Card.Title style={{color:'#c59b6b'}}>Caspar Morley </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">Senior Consultant</Card.Subtitle>
            <Card.Text style={{textAlign:'justify'}}>
            Caspar has dealt with real estate throughout his 30 year career. Working for two of the largest International real estate consultancies, Savills and Knight Frank, he gained a wealth of experience and contacts in the industry. He moved to the client side of the industry in 2007, working for development companies and government agencies in the UK and Middle East.
 
 Utilising this experience and as a member of the Royal Institute of Chartered Surveyors, Caspar is able to not only identify real estate investment opportunities at an early stage, he is also able to manage all stakeholders from cradle to grave. Caspar has worked on all major urban locations within the UK and more broadly into Europe. His commitment to the investor and real estate continues with his work with MBN.”
            </Card.Text>

          </Card.Body>
          <Card.Img src={senior} style={{ borderRadius: 150, width: 180, height: 180 }} />
        </Card>

        <br></br>
        <Card>  
        <Card.Img src={sahad} style={{ borderRadius: 150, width: 150, height: 180 }} />     
          <Card.Body>
            <Card.Title style={{color:'#c59b6b'}}>Shahad Al-Saudi</Card.Title>
            <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
            <Card.Text style={{textAlign:'justify'}}>
            Shahad has five years’ experience in the international property market with a focus on London’s New Developments. Her past experience in luxury retail shows in her exceptional customer service and commitment to the clients needs. Shahad’s fluency is Arabic, international exposure and investment background has made her a true asset to our team.
            </Card.Text>

          </Card.Body>
        
        </Card>
        <br></br>
        <Card>       
          <Card.Body>
            <Card.Title style={{color:'#c59b6b'}}>Bayram Aktepe</Card.Title>
            <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
            <Card.Text style={{textAlign:'justify'}}>
            Bayram is passionate about landing big dreams.
A leader in formulating winning strategies and delivering mega projects, he has made high-risk, righ-reward innovation his forte.
With academic qualifications in aeronautical engineering and finance, leadership and analytical skills and over 30 years of experience in consulting, advisory and government roles, Bayram brings energy and wisdom to every job.
The application of cutting-edge technology into strategy, delivery, risk management, reporting and business intelligence is where he excels.  Thorough research, detailed analysis, use of evidence / data-driven decision making and conducting financial feasibility analysis is what Bayram also brings to our clients.
With capability and experience in diverse sectors including banking, telecommunications, mega-events, education and aeronautics sectors, Bayram ‘s breadth and depth make thinking out-of-the box, creative solutions to your problems the norm.
            </Card.Text>

          </Card.Body>
          <Card.Img src={bayram } style={{ borderRadius: 150, width: 100, height: 180 }} />
        </Card>
        <br></br>
        

</Container>
   
    <Partners/>
        </div>
    )
}
export default Mainabout