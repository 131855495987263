import React from "react";
import { FaBeer } from 'react-icons/fa';
import {FaLocationArrow} from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaMailBulk } from "react-icons/fa";
import { FaInstagram ,FaTwitter,FaFacebook} from "react-icons/fa";
import img1 from '../../Assets/Images/contact.jpg'
import img from '../../Assets/Images/side-view-men-with-safety-vests-shaking-hands.jpg'
function Contact(){
    return(
        <div>
            <section className="page-title centred" style={{backgroundImage: `url(${img1 })`}}>
        <div className="container">
            <div className="content-box"><h2>Contact</h2></div>
        </div>
    </section>
    
    <section className="google-map-section map-style-two">
        <div className="google-map-area">
            <div >
           
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.9293086380903!2d-0.14371409999999998!3d51.5145129!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876052ad6f571d3%3A0x56d81e3bd0ccd36f!2sLilly%20House%2C%2013%20Hanover%20Square%2C%20London%20W1S%201HN%2C%20UK!5e0!3m2!1sen!2sin!4v1692962589260!5m2!1sen!2sin"  width="1350" height="500"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> 

            </div>
        </div>
    </section>
   
    <div className="social-area centred">
        <div className="container">
            <ul className="social-list">
                {/* <li><a href="#"><i className="fa fa-facebook"><FaFacebook></FaFacebook></i></a></li> */}
                <li><a href="https://www.instagram.com/_mbnconsultancy/?igshid=MzRlODBiNWFlZA%3D%3D"><i className="fa fa-dribbble"><FaInstagram></FaInstagram></i></a></li>
                {/* <li><a href="#"><i className="fa fa-twitter"><FaTwitter></FaTwitter></i></a></li> */}
                {/* <li><a href="#"><i className="fa fa-vimeo"></i></a></li> */}
                <li><a href="mailto:info@mbnconsultancy.co.uk"><i class="fa fa-pinterest-p"><FaMailBulk></FaMailBulk></i></a></li>
            </ul>
        </div>
    </div>
   
    <section className="contact-section sec-pad">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 contact-column">
                    <div className="contact-info">
                        <div className="title"><h4>Address</h4></div>
                        <div className="single-item">
                            <div className="icon-box"><i ><FaLocationArrow ></FaLocationArrow></i></div>
                            <div className="text">13 Hanover Square, Mayfair, London,
WS1 1HN, UK</div>
                        </div>
                        <div className="title"><h4>Phone</h4></div>
                        <div className="single-item">
                            <div className="icon-box"><i ><FaPhone ></FaPhone></i></div>
                            <div className="text">+44  20 3633 5190</div>
                        </div>
                        <div className="title"><h4>Mail</h4></div>
                        <div className="single-item">
                            <div className="icon-box"><i ><a href="mailto:info@mbnconsultancy.co.uk"><FaMailBulk></FaMailBulk></a></i></div>
                            <div className="text">info@mbnconsultancy.co.uk</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 contact-column">
                    <div className="contact-form-area">
                        <div className="title"><h4>Get In Touch</h4></div>
                        <div className="text">Ready to shape your financial future? Reach out and let's start the journey together.</div>
                        <form id="contact-form" name="contact_form" className="default-form" >
                            <div className="row">
                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                    <input type="text" name="form_name" value="" placeholder="Your Name *" required=""/>
                                </div>
                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                    <input type="email" name="form_email" value="" placeholder="Email Address *" required=""/>
                                </div>
                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                    <input type="text" name="form_subject" value="" placeholder="Phone number" required=""/>
                                </div>
                                {/* <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                    <div className="select-box">
                                        <select name="Property" className="selectpicker">
                                            <option> financial advice</option>
                                            <option>Type</option>
                                            
                                        </select>
                                    </div>
                                </div> */}
                                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                    <textarea placeholder="Your Message" name="form_message" required=""></textarea>
                                </div>
                                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                    <button className="theme-btn" ><a href="mailto:info@mbnconsultancy.co.uk">Submit</a></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </div>
    )
}
export default Contact