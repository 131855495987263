import React from "react";
import img from '../../Assets/Images/office-scene-close-up.jpg'
import img1 from '../../Assets/Images/businessmen-hands-white-table-with-documents-drafts.jpg'
import img2 from '../../Assets/Images/ablog.jpg'
import img3 from '../../Assets/Images/unrecognizable-business-partners-working-with-statistic-charts-businessman-holding-tablet-professional-content-businesswoman-making-notes-statistics-communication-partnership-concept.jpg'
import img4 from '../../Assets/Images/businessmen-elegant-suits-business-meeting-discussing-new-project-office.jpg'

function Blog(){
    return(
        <div>
<section className="page-title centred"  style={{backgroundImage: `url(${img2 })`}}>
        <div className="container">
            <div className="content-box"><h2>Blog</h2></div>
        </div>
    </section>
   
    <section className="news-section blog-standard sidebar-page-container">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                    <div className="blog-standard-content default-blog-content">
                        <div className="news-content">
                            <figure className="img-box"><a ><img src={img} alt=""/></a></figure>
                            <div className="lower-content">
                                <div className="date">17 May</div>
                                <h4><a >Best Tips For Having an Estate Sale</a></h4>
                                <div className="text">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem</div>
                            </div>
                        </div>
                        <div className="news-content">
                            <figure className="img-box"><a ><img src={img1} alt=""/></a></figure>
                            <div className="lower-content">
                                <div className="date">20 August</div>
                                <h4><a>6 Ways Home Buyers Mess Up Getting a Mortgage</a></h4>
                                <div className="text">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem</div>
                            </div>
                        </div>
                       
                        {/* <ul className="pagination">
                            <li><a href="#"><i className="fa fa-angle-left"></i></a></li>
                            <li><a href="#">1</a></li>
                            <li><a href="#" className="active">2</a></li>
                            <li><a href="#">3</a></li>
                            <li><a href="#">4</a></li>
                            <li><a href="#"><i className="fa fa-angle-right"></i></a></li>
                        </ul> */}
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 sidebar-side">
                    <div className="blog-sidebar default-sidebar-content">
                        <div className="sidebar-search sidebar-widget">
                            <form action="#" method="post">
                                <div className="form-group">
                                    <input type="search" name="search-field" placeholder="Search" required=""/>
                                    <button type="submit"><i className="fa fa-search"></i></button>
                                </div>
                            </form>
                        </div>
                        <div className="sidebar-categories sidebar-widget">
                            <div className="sidebar-title"><h4>Categories</h4></div>
                            <ul className="list">
                                <li><a href="#">Commercial</a></li>
                                <li><a href="#">Residential</a></li>
                                <li><a href="#">Insurance consultancy</a></li>
                                <li><a href="#">Investment advice</a></li>
                                <li><a href="#">Armed Security</a></li>
                            </ul>
                        </div>
                        <div className="sidebar-post sidebar-widget">
                            <div className="sidebar-title"><h4>Recent Post</h4></div>
                            <div className="post">
                                <div className="single-post">
                                    <figure className="img-box"><a ><img src={img} alt=""/></a></figure>
                                    <div className="text"><a >These Are My Favorite Ways to Finance Multifamily Investments</a></div>
                                </div>
                                <div className="single-post">
                                    <figure className="img-box"><a><img src={img3} alt=""/></a></figure>
                                    <div className="text"><a >The 13 Tell-Tale Traits of a Scammy Real Estate Investment Guru</a></div>
                                </div>
                                <div className="single-post">
                                    <figure className="img-box"><a ><img src={img4} alt=""/></a></figure>
                                    <div className="text"><a>5 Business Books That Changed My Real Estate Investing Life</a></div>
                                </div>
                                <div className="single-post">
                                    <figure className="img-box"><a ><img src={img} alt=""/></a></figure>
                                    <div className="text"><a>The 13 Tell-Tale Traits of a Scammy Real Estate Investment Guru</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="sidebar-tag sidebar-widget">
                            <div className="sidebar-title"><h4>Tags Clouds</h4></div>
                            <ul className="tag-list clearfix">
                                <li><a href="#">Commercial</a></li>
                                <li><a href="#">consultancy</a></li>
                                <li><a href="#">Insurance</a></li>
                                <li><a href="#">Ideas</a></li>
                                <li><a href="#">Investment</a></li>
                                <li><a href="#">Advice</a></li>
                                <li><a href="#">Armed </a></li>
                                <li><a href="#">Security</a></li>
                                <li><a href="#">Report</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   
        </div>
    )
}
export default Blog