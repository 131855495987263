import React from "react"
import { Fade } from "react-bootstrap"
import "./Hero.css"
import img from './construction-site-silhouettes.jpg'
import Carousel from 'react-bootstrap/Carousel';
import { Row,Col } from 'react-bootstrap';
import About from "../../About/about";
import Services from "../../Services/services";
import img2 from '../../../Assets/Images/top-view-businessman-analyzing-financial-documents-discussing-company-strategy.jpg'
import img1 from '../../../Assets/Images/office-scene-close-up.jpg'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


const Hero = () => {
  return (
    <>
      <section class="main-slider">
    <Row>
        <Col  sm={12} md={12}>
        <Carousel fade >
      <Carousel.Item >
     <img src={img2} style={{width:'100%',height:'100%'}}  />
        <Carousel.Caption style={{marginTop:30}}>
          <h1 style={{color:'black'}}><b></b></h1>
          <p><b></b></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={img1} style={{width:'100%',height:'100%'}}/>
        <Carousel.Caption>
        <h1 style={{color:'orange'}}> <b></b></h1>
          <p><b></b></p>
        </Carousel.Caption>
      </Carousel.Item>
      
    </Carousel>
        </Col>
        </Row>
    </section>
      
      <About></About>
      <Services></Services>
     
    </>
  )
}

export default Hero
