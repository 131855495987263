import React from "react";

import img from '../../Assets/Images/unrecognizable-business-partners-working-with-statistic-charts-businessman-holding-tablet-professional-content-businesswoman-making-notes-statistics-communication-partnership-concept.jpg'
function Partners(){
    return(
        <div>
           
                <section className="testimonial-section sec-pad centred" style={{backgroundImage: `url(${img })`,alignContent:'center',marginLeft:20}}>
        <div className="container">
            <h2 className="sec-title"> OUR PARTNERS</h2>
            <div className="title-text" style={{fontWeight:200,textAlign:'justify'}}>At MBN, we value strong partnerships that enhance our ability to 
serve our clients and provide comprehensive investment consultancy 
services. We carefully select and collaborate with trusted partners who 
share our commitment to excellence, integrity, and client-centricity. 
Our partners are an integral part of our ecosystem, contributing their 
expertise, resources, and specialized knowledge to support our clients 
financial goals.</div>
            <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 offset-lg-2">
                    
                </div>
            </div>
        </div>
    </section>
                
          
         
        </div>
    )
}
export default Partners