import React from "react";
import { Row,Col } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import img from '../../Assets/Images/african-american-woman-safety-helmet-working-near-model-building.jpg'
import img1 from '../../Assets/Images/businessmen-elegant-suits-business-meeting-discussing-new-project-office.jpg'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './header.css'
import Hero from "../Home/hero/Hero";
import About from "../About/about";
import Services from "../Services/services";
import logo from '../../Assets/Images/mbn-01.png'

function Header(){
    return(
        <div>
              <Navbar   fixed="top"  className='head' style={{backgroundColor:'#003366'}}   expand="lg"  >
    <Container >
    <div   className="d-flex justify-content-end">
      <div >
    <img className='logo' src={logo}  />
    </div>
    <div style={{marginTop:20}}>
      <Navbar.Toggle style={{marginLeft:120}} aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
      
        <Nav className="me-auto">
        <Nav.Link ><div className='hascbpl'></div></Nav.Link>
          <Nav.Link ><div className='hascbpl'></div></Nav.Link>
          <Nav.Link ><div className='hascbpl'></div></Nav.Link>
          <Nav.Link ><div className='hascbpl'></div></Nav.Link>
         <Nav.Link href="/"><div className='hascbpl'>HOME</div></Nav.Link>
          <Nav.Link href="/mainabout"><div className='hascbpl'>ABOUT</div></Nav.Link>
          <Nav.Link href="/mainservices"><div className='hascbpl'>SERVICES</div></Nav.Link>
        <Nav.Link href="/blog"><div className='hascbpl'>BLOG</div></Nav.Link>
          <Nav.Link href="/contact"><div className='hascbpl'>CONTACT</div></Nav.Link>
          </Nav>
          </Navbar.Collapse>
      </div>
      </div>
      </Container>
  </Navbar>
   
    {/* <section class="main-slider">
    <Row>
        <Col  sm={12} md={12}>
        <Carousel fade >
      <Carousel.Item >
     <img src={img} style={{width:'100%',height:'100%'}}  />
        <Carousel.Caption style={{marginTop:30}}>
          <h1 style={{color:'orange'}}><b>Consultancy Services</b></h1>
          <p><b>All Type  Services Available</b></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={img1} style={{width:'100%',height:'100%'}}/>
        <Carousel.Caption>
        <h1 style={{color:'orange'}}> <b>Financial Services</b></h1>
          <p><b>All Kind Of  Service</b></p>
        </Carousel.Caption>
      </Carousel.Item>
      
    </Carousel>
        </Col>
        </Row>
    </section> */}
    
        </div>
    )
}
export default Header