import React from "react";
import img from '../../Assets/Images/office-scene-close-up.jpg'
import { Fade } from "react-reveal";
import img1 from '../../Assets/Images/map-lying-wooden-table.jpg'
import img2 from '../../Assets/Images/businesspeople-working-finance-accounting-analyze-financi.jpg'
import img3 from '../../Assets/Images/businessmen-elegant-suits-business-meeting-discussing-new-project-office.jpg'
import img4 from '../../Assets/Images/stock-exchange-information-board-graphic.jpg'
import img5 from '../../Assets/Images/abcd.jpg'
function Mainservices(){
    return(
        <div>
<section className="page-title centred" style={{backgroundImage: `url(${img5 })`}}>
        <div className="container">
            <div className="content-box"><h2>Services</h2></div>
           
        </div>
    </section>
    <section className="destin-details destin-style-two">
        <div className="container">
            <h2 className="sec-title centred">Explore Our Services </h2>
            <div className="title-text centred">MBN ’s advisory service gives investors access to expert 
support as they manage their investment portfolio in the 
UK in a flexible, efficient way. The reassurance, trust and 
independent thinking across a range of skillsets, including 
Corporate Governance, General Counsel, Finance 
Director, Commercial Strategy, People & Operations, 
Corporate Finance and Data Protection.</div>
            
            <div className="tab-details-content">
                <div className="tab-content" id="details">
                    <div className="row">
                       
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            
                            <figure  className="img-box wow slideInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <Fade left>
                                <img style={{width:'100%',height:400}}   src={img}  alt=""/>
                                    </Fade></figure>
                        </div>
                       
                        
                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="content-box">
                                <Fade right> <h4>ASSET MANAGEMENT
INVESTMENT CONSULTANCY</h4></Fade>
                               
                                <div className="text" style={{textAlign:'justify'}}>
                                Customized strategies to ensure optimal adequacy 
and profiling.
In addition to financial consulting, wealth 
management is our company’s core business.
Wealth protection and growth are our clientele’s 
main goals, and these become our own , these are 
achieved by monitoring and analyzing the constant 
changes on the global economic markets and the 
rules that regulate them. Once we have agreed 
upon expectations and objectives with our clients, 
we get to work by developing the most effective 
asset management strategy. As the case may be, 
this strategy may appear to be prudent, flexible or 
dynamic depending on the desired risk tolerance 
level, calculated on specific and diversified needs, in 
a constantly changing scenario.
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
             
            
            </div>
          
        </div>
    </section>
    <section className="destin-details destin-style-two" >
        <div className="container" style={{marginTop:-250}}>
           
            <div className="tab-details-content">
               
             
            
            </div>
            <div className="tab-details-content">
                <div className="tab-content" id="details">
                    <div className="row">
                       
                      
                        
                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="content-box">
                                <Fade left> <h4>PRIVATE EQUITY AND ALTERNATIVE 
FINANCE</h4></Fade>
                               
                                <div className="text" style={{textAlign:'justify'}}>
                                For our clients we design and create tailor-made solutions for investing 
in the private equity and venture capital market, also by setting up 
bespoke investment vehicles, securitizations, club deal operations.
                                </div>
                              
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            
                            <figure  className="img-box wow slideInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <Fade right>
                                <img style={{width:'100%',height:'100%'}}   src={img1}  alt=""/>
                                    </Fade></figure>
                        </div>
                       
                    </div>
                </div>
             
            
            </div>
        </div>
    </section>
    <section className="destin-details destin-style-two">
        <div className="container"  style={{marginTop:-150}}>
            
            
            <div className="tab-details-content">
                <div className="tab-content" id="details">
                    <div className="row">
                       
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            
                            <figure  className="img-box wow slideInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <Fade left>
                                <img style={{width:'100%',height:'100%'}}   src={img2}  alt=""/>
                                    </Fade></figure>
                        </div>
                       
                        
                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="content-box">
                                <Fade right> <h4>ASSET PLANNING
AND OPTIMIZATION</h4></Fade>
                               
                                <div className="text" style={{textAlign:'justify'}}>
                                We study solutions for heritage planning together 
with our customers, supporting them in extraordinary 
corporate operations (M&As) and in business continuity 
and succession
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
             
            
            </div>
          
        </div>
    </section>
    <section className="destin-details destin-style-two" >
        <div className="container" style={{marginTop:-250}}>
           
            <div className="tab-details-content">
               
             
            
            </div>
            <div className="tab-details-content">
                <div className="tab-content" id="details">
                    <div className="row">
                       
                      
                        
                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="content-box">
                                <Fade left> <h4>MANAGEMENT OF
REAL ESTATE ASSETS</h4></Fade>
                               
                                <div className="text" style={{textAlign:'justify'}}>
                                Because real estate often amounts to little used 
wealth, we help our customers to make the assets more 
liquid, through collateral operations, or to improve its 
profitability even by setting up dedicated funds.
                                </div>
                              
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            
                            <figure  className="img-box wow slideInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <Fade right>
                                <img style={{width:'100%',height:'100%'}}   src={img3}  alt=""/>
                                    </Fade></figure>
                        </div>
                       
                    </div>
                </div>
             
            
            </div>
        </div>
    </section>
    <section className="destin-details destin-style-two">
        <div className="container"  style={{marginTop:-150}}>
            
            
            <div className="tab-details-content">
                <div className="tab-content" id="details">
                    <div className="row">
                       
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            
                            <figure  className="img-box wow slideInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <Fade left>
                                <img style={{width:'100%',height:'100%'}}   src={img4}  alt=""/>
                                    </Fade></figure>
                        </div>
                       
                        
                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="content-box">
                                <Fade right> <h4>RISK MANAGEMENT</h4></Fade>
                               
                                <div className="text" style={{textAlign:'justify'}}>
                                Risk is an intrinsic component of any investment, but 
the correct evaluation and management of risk means 
that every action can be planned in a safer and more
conscious way.
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
             
            
            </div>
          
        </div>
    </section>
    <section className="testimonial-section sec-pad centred" style={{backgroundImage: `url(${img2 })`}}>
        <div className="container">
            <h2 className="sec-title"> "Transforming challenges into prosperous financial opportunities."</h2>
            <div className="title-text" style={{fontWeight:200}}>MBN ’s advisory service gives investors access to expert 
support as they manage their investment portfolio in the 
UK in a flexible, efficient way...</div>
            <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 offset-lg-2">
                    
                </div>
            </div>
        </div>
    </section>
 
        </div>
    )
}
export default Mainservices