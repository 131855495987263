import React from "react";
import { Col,Container,Row } from "react-bootstrap";
import img from '../../Assets/Images/businessmen-hands-white-table-with-documents-drafts.jpg'
import img1 from '../../Assets/Images/map-lying-wooden-table.jpg'
import { Fade } from 'react-reveal';
import './about.css'
import Partners from "./partner";
function About(){
    return(
        <div  >
            
          {/* <h1>About</h1> */}
          {/* <Row>
        <Col sm={8}>
        <Fade left>
        <img style={{width:'80%',height:300,marginLeft:5}} src={img}/>
                </Fade></Col>
        <Col sm={4}>
            <h1 >About MBN</h1>
            <p> where vision meets expertise, and every blueprint transforms into a masterpiece. " where vision meets expertise, and every blueprint transforms into a masterpiece. "</p>
        </Col>
      </Row> */}
      <section className="about-section">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-10 col-sm-12">
                  <Fade left><figure className="img-box wow slideInLeft" data-wow-delay="0ms" data-wow-duration="1500ms"><img style={{width:'100%',height:300}} src={img} alt=""/></figure></Fade>  
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="about-content">
                        <h2>ABOUT MBN</h2>
                        <div className="text">
                           <Fade right>
<p >Welcome to MBN Investment Consultancy, your trusted partner for 
comprehensive investment advisory services in the United Kingdom. 
We are dedicated to helping individuals, businesses, and institutions 
navigate the complex world of investments, providing expert guidance 
and strategic solutions to achieve their financial objectives.</p>
<p>At MBN Investment Consultancy, we understand that making sound 
investment decisions requires a deep understanding of the markets, 
diligent analysis, and a tailored approach. With our team of experienced 
consultants and financial experts, we offer a wide range of services 
designed to meet your unique investment needs.</p></Fade> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="property-highlight">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="highlight-content">
                        <br></br>
                        <h3>MBN HIGHLIGHTS</h3><br></br>
                        <div className="top-text">
                        MBN ensures that at all times it acts with:
                        </div>
                        <div className="content-box">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-item">
                                        <h4><a href="#"> Independence</a></h4>
                                        <div className="text">Independence in financial consultancy ensures unbiased advice by prioritizing client interests over personal gains. <div class="link-box" ><a style={{color:'darkblue'}} href="/mainabout">Read More</a></div></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-item">
                                        <h4><a href="#">Impartiality</a></h4>
                                        <div className="text">unbiased recommendations devoid of favoritism towards specific products or services. <div class="link-box" ><a style={{color:'darkblue'}} href="/mainabout">Read More</a></div></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-item">
                                        <h4><a href="#">A specialist skillset</a></h4>
                                        <div className="text">A specialist skillset in financial consultancy encompasses a deep expertise in intricate financial strategies.<div class="link-box" ><a style={{color:'darkblue'}} href="/mainabout"><br></br>Read More</a></div></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-item">
                                        <h4><a href="#">Cost effectiveness</a></h4>
                                        <div className="text">Cost effectiveness in financial consultancy signifies delivering high-value advisory services while optimizing resource allocation. <div class="link-box" ><a style={{color:'darkblue'}} href="/mainabout">Read More</a></div></div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <figure className="img-box"><img src={img1} style={{width:'100%'}} alt=""/></figure>
                </div>
            </div>
        </div>
    </section>
   
        </div>
    )
}
export default About