import React from 'react'
import {BrowserRouter as Router,Switch} from 'react-router-dom'
import About from './Components/About/about'
import Mainabout from './Components/Mainabout/Mainabout'
import Mainservices from './Components/Mainservices/Mainservices'
import Hero from './Components/Home/hero/Hero'
import Contact from './Components/Contact/Contact'
import Blog from './Components/Blog/Blog'


function rout() {
  return (
    <div>
       <Router>
         <Switch>
              {/* <Home exact path='/' component={Home} /> */}
              <Mainabout  exact path= '/mainabout' component={Mainabout} />
              <Contact  exact path= '/contact' component={Contact} />
              <Mainservices  exact path= '/mainservices' component={Mainservices} />
              < Hero exact path= '/' component={Hero}/>
              <Blog exact path='/blog' component={Blog}/>
              {/* <Services exact path='/services' component={Services} />
              <Contact exact path='/contact' component={Contact} />
              <Blog exact path='/blog' component={Blog} />
              <Slide exact path='/slide' component={Slide} /> */}
              
          </Switch>
      </Router>
    </div>
  )
}

export default rout
